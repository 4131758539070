@import "../../sass/main.scss";

.light {
  &.logo {
    color: $background-color !important;
  }

  .nav-link {
    color: $background-color !important;

    &::before {
      background-color: $background-color !important;
    }
  }

  .btn--dark {
    border: 0.2em solid $background-color;
    background-color: transparent;
    color: $background-color;

    &:hover {
      color: $font-color;
      background-color: $background-color;
    }
  }
}

.navbar {
  .contact {
    position: absolute;
    display: flex;

    pointer-events: none;
    overflow: hidden;

    top: 0;
    left: 0;

    height: 100vh;
    width: 100%;

    @include for-phone-only {
      .panel {
        &.left {
          display: none;
        }
        &.right {
          width: 100%;

          .form-wrapper {
            width: 100%;
          }
        }
      }
    }

    @include for-tablet-portrait-up {
      .panel {
        &.left {
          display: none;
        }
        &.right {
          width: 100%;

          .form-wrapper {
            min-width: 30em;
          }
        }
      }
    }

    @include for-tablet-landscape-up {
      .panel {
        &.left {
          display: flex;
          width: 60%;
        }
        &.right {
          width: 40%;

          .form-wrapper {
            min-width: 25em;
          }
        }
      }
    }

    .panel {
      position: relative;
      z-index: 8;

      height: 100%;
      transition: 0.2s transform;

      padding: $spacing;

      display: flex;
      flex-direction: column;

      justify-content: center;

      &.left {
        background-color: $background-color;
        transform: translateX(-100%);

        h1 {
          font-family: "Montserrat Alternates", sans-serif;
          font-size: 2em;
        }
      }

      &.right {
        background-color: $font-color;
        transform: translateX(100%);

        color: $background-color;

        h1 {
          font-size: 2em;
        }

        .form-wrapper {
          margin: auto;

          form {
            width: 100%;
            margin-top: 1em;

            input,
            textarea {
              border: 0.2em solid $background-color;
              color: $background-color;
            }

            .btn--submit {
              margin-top: 1em;
              font-size: 0.9em;
              background-color: $font-color;
              border: 0.2em solid $background-color;
              transition: 0.2s background-color;

              &:hover {
                background-color: $background-color;
                color: $font-color;
              }
            }
          }
        }
      }
    }

    &.active {
      pointer-events: auto !important;
      .panel {
        &.left {
          transform: translateX(0);
        }

        &.right {
          transform: translateX(0);
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100%;

    overflow: hidden;

    pointer-events: none;

    &.active {
      pointer-events: auto !important;

      .panel {
        transform: translateX(0);
      }
    }

    .panel {
      height: 100%;
      background-color: $font-color;

      transform: translateX(-100%);

      position: relative;
      z-index: 7;

      height: 100%;
      transition: 0.2s transform;

      padding: $spacing;

      display: flex;
      flex-direction: column;

      justify-content: center;

      .nav-link {
        margin: 0.3em 0;
        font-size: 3em;
        font-weight: bold;
        text-decoration: none;
        color: $background-color;
        position: relative;
        width: fit-content;
        cursor: pointer;
        user-select: none;

        &::before {
          content: "";
          position: absolute;
          height: 0.1em;
          background-color: $background-color;
          border-radius: 1em;

          left: 0;
          bottom: 0;
          width: 0;

          transition: 0.2s width;
        }

        &:hover {
          &::before {
            width: 50%;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;

    .logo {
      position: relative;
      z-index: 10;

      font-family: "Montserrat Alternates", sans-serif;
      font-weight: bold;
      font-size: 1.9em;

      color: $font-color;
      text-decoration: none;
    }

    .nav-links {
      position: relative;
      z-index: 10;

      display: flex;
      align-items: center;
      font-size: 1.1em;

      @include for-phone-only {
        .btn--dark {
          display: block;
        }

        .nav-link {
          display: none;
        }
      }

      @include for-tablet-portrait-up {
        .btn--dark {
          display: block;
        }
        .nav-link {
          display: none;
        }

        &.active {
          display: block;
        }
      }

      @include for-tablet-landscape-up {
        .btn--dark {
          display: none;
        }
        .nav-link {
          display: block;
        }

        &.active {
          display: none;
        }
      }

      .btn--dark {
        font-size: 0.8em;
        padding: 0.5em 2em;

        @include for-phone-only {
          &.visible {
            display: block !important;
          }

          &.hidden {
            display: none !important;
          }
        }

        @include for-tablet-portrait-up {
          &.visible {
            display: block !important;
          }

          &.hidden {
            display: none !important;
          }
        }
      }

      .nav-link {
        color: $font-color;
        text-decoration: none;
        margin-left: 3.5em;
        position: relative;
        padding: 0.3em 0;
        user-select: none;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          height: 0.15em;
          background-color: $font-color;
          border-radius: 1em;

          left: 0;
          bottom: 0;
          width: 0;

          transition: 0.2s width;
        }

        &:hover {
          &::before {
            width: 50%;
          }
        }

        &.active {
          color: $font-color;
          font-weight: 500;

          &::before {
            width: 50%;
          }
        }
      }
    }
  }
}
