.error-section {
  height: 100%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;

    text-align: center;

    transform: translate(-50%, -50%);

    h1 {
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 3em;
    }

    p {
      font-weight: bold;
    }
  }
}
