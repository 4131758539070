@import "../../sass/main.scss";

.footer {
  margin-top: auto;
  font-size: 1.1em;

  .container {
    display: flex;
    align-items: center;
    z-index: 2;

    .social-link {
      color: $primary-color;
      text-decoration: none;
      margin-right: 2em;

      font-weight: 500;
    }

    .rule {
      height: 0.15em;
      width: 100%;
      background-color: $primary-color;
    }
  }
}
