@import "../../sass/main.scss";

.card {
  min-height: 23em;

  border: 0.2em solid $font-color;
  padding: 1.5em;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  background-color: $bg-shade1;

  .project {
    height: 100%;

    margin-right: 2rem;

    display: flex;
    flex-direction: column;

    align-items: flex-start;

    &__type {
      font-size: 0.9rem;
    }

    &__title {
      font-size: 2rem;
      margin: 1rem 0;
    }

    &__link {
      margin-top: auto;
      padding-bottom: 0.1em;
      bottom: 0;

      padding-top: 1rem;
      font-weight: bold;

      color: $font-color;
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0.1em;
        width: 0%;
        background-color: $font-color;
        transition: width 0.1s;
      }

      &:hover {
        &::after {
          width: 50%;
        }
      }
    }
  }
}

.feature-card {
  @extend .card;

  .img-wrapper {
    min-height: 20em;
    height: 100%;
    width: 80%;
    background-position: center;
    background-size: cover;
    border: 0.2em solid $font-color;
  }
}

.blog-card {
  @extend .card;
}
