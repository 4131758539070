@import "../../sass/main.scss";

.page {
  .feature-img {
    margin-block: 3em;
    background-size: cover;
    background-position: center;

    margin-inline: auto;

    height: 25em;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .heading {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 2.5em;

    margin-bottom: 0.5em;
  }

  .description {
    margin-bottom: 2.5rem;
  }

  .image-section {
    margin-bottom: 2.5rem;

    .active-image {
      width: 100%;
      border: 0.2em $font-color solid;

      margin-bottom: 0.5em;
    }

    .scroll-wrapper {
      display: flex;
      overflow-x: auto;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .preview {
      display: flex;
      gap: 1em;

      .preview-image {
        cursor: pointer;
        height: fit-content;
        width: 12em;
        border: 0.2em $font-color solid;

        background-position: center;
        background-size: contain;

        img {
          width: 100%;
          display: block;
        }
      }
    }
  }

  ul,
  ol {
    margin-left: 1.1em;
  }
}
